<script setup lang="ts">
import {ArrowUpRightIcon} from "@heroicons/vue/24/outline";
import type {Project} from "~/interface";
const props = defineProps<{ full?: boolean, value: Project }>()

const emoji = computed(() => {
  if (props.value.type === "bot")
    return "🤖"
  if (props.value.type === "channel")
    return "📢"
  if (props.value.type === "group")
    return "👥"
})
</script>

<template>
  <div class="relative group">
    <nuxt-link
      :class="[
        'block rounded-lg bg-white hover:bg-gray-100 duration-200 cursor-pointer space-y-2',
        props.full ? 'border p-3' : 'p-2'
      ]"
      :to="`/${props.value.id_string}`"
    >
      <div class="flex gap-2">
        <div class="flex-none default-thumb rounded-xl" :class="props.full ? 'w-10 h-10': 'w-12 h-12'">
          <img
            v-if="props.value.media"
            class="rounded-2xl"
            :src="props.value.media.path"
            alt=""/>
          <img v-else src="/default.jpg" class="rounded-2xl" alt="">
        </div>
        <div class="flex-1 space-y-1 overflow-hidden">
          <div class="flex gap-1 items-center">
            <div class="text-sm font-semibold">{{ emoji }}</div>
            <div class="w-full text-ellipsis truncate leading-none">{{ props.value.name }}</div>
          </div>
          <div v-if="props.full"></div>
          <div v-else class="text-xs text-gray-500 line-2">{{ props.value.desc }}</div>
        </div>
      </div>
      <div v-if="props.full" class="space-y-2 text-xs">
        <div class="line-2 h-5">
          <div class="text-gray-500 text-ellipsis truncate">{{ props.value.desc }}</div>
        </div>
        <div v-if="false" class="flex justify-between items-center">
          <div class="flex gap-1 items-center">
            <div v-if="false" class="w-6">
              <img
                class="rounded-full"
                src="https://pan16.larksuitecdn.com/static-resource/v1/v2_66f9e2b6-e7e4-4e57-acab-73a3f431d0ch~?image_size=noop&cut_type=&quality=&format=image&sticker_format=.webp"
                alt=""
              >
            </div>
            <span class="font-semibold">{{ props.value.user.first_name || '🤖'}}</span>
          </div>
          <action-vote :instance="props.value"/>
        </div>
      </div>
    </nuxt-link>
    <div class="absolute top-2 right-2 hidden group-hover:block">
      <nuxt-link
        :href="`https://t.me/${props.value?.id_string}`" target="_blank"
        class="block p-1 rounded-lg border cursor-pointer bg-white">
        <ArrowUpRightIcon class="w-3 h-3"/>
      </nuxt-link>
    </div>
  </div>
</template>

<style>
.default-thumb {
  background: url("/default.jpg");
  background-size: cover;
}
</style>
